import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex, Grid } from '@chakra-ui/react';

import DealSummaryCard from './components/DealSummaryCard';
import MeetingCard from './components/MeetingCard';
import TalkingPointsCard from './components/TalkingPoints';
import ActionItems from './components/ActionItems';
import ProspectListCard from './components/ProspectListCard';
import { Deal, GetCollectionItemResponse, Meeting } from 'views/deals/Deal';
import { SidebarContext } from 'contexts/SidebarContext';
import DealCard from '../dashboard/components/DealCard';
import Card from 'components/card/Card';

const dealItemUrl = "https://appwise-functions.azurewebsites.net/api/get-deal-one?code=WvZaEIQKR4eSv-P1C22Zf1YEkffLGonSthtLGElmEVtdAzFuFpSiyA%3D%3D";

export default function DealsOverview() {
  let { dealId } = useParams<{ dealId: string }>();
  console.log(`dealId=${dealId}`);
  const [deal, setDeal] = useState<Deal>()
  const { setBrandText } = useContext(SidebarContext);

  useEffect(() => {
    if (deal) {
      return;
    }
    fetch(dealItemUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ dealId })
    })
      .then(res => res.json())
      .then(data => {
        let { item: deal } = data as GetCollectionItemResponse;
        console.log('data: ', data);
        console.log('deal: ', deal);
        console.log({ meetings: deal?.meetings })
        setDeal(deal);
        setBrandText(`Deal with ${deal?.name}`);
      })
      .catch((error: Error) => {
        console.log(error);
      });
  }, []);

  if (!deal) return null;
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Grid
        w='100%'
        display={{ base: 'flex', md: 'grid' }}
        flexDirection={{ base: 'column', md: 'unset' }}
        templateColumns={{ md: '1fr 2.6fr' }}
        templateRows={{ base: 'repeat(2, 1fr)', md: '1fr' }}>
        <Box me={{ base: '0px', md: '20px' }} mb={{ base: '20px', md: '0px' }} minW={'310px'}>
          <DealCard deal={deal} variant={'overview'} />
          {deal.stakeholdersList && <ProspectListCard prospects={deal.stakeholdersList} dealId={deal._id} headerText={'Stakeholders'} variant={'dealOverview'} mt='20px' />}
          {deal.actionItems && deal.actionItems.length > 0 && <ActionItems actionItems={deal.actionItems} header={'Action Items'} />}
          {deal.recommendedActionItems && deal.recommendedActionItems.length > 0 && <ActionItems actionItems={deal.recommendedActionItems} variant={'recommended'} />}
        </Box>
        <Flex direction='column'>
          <MeetingCard
            meetings={deal.meetings}
            variant='full'
            onSelectionChanged={function (selectedMeeting: Meeting): void { }}
            mb={'20px'} />
          <TalkingPointsCard talkingPoints={deal.talkingPoints} />
          <DealSummaryCard deal={deal} />
        </Flex>
      </Grid>
    </Box>
  );
}
