import { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  UnorderedList,
  ListItem,
  Button,
  Spinner,
  SkeletonText,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { GetCollectionItemResponse, Meeting } from 'views/deals/Deal';

const getCollectionItemUrl = "https://appwise-functions.azurewebsites.net/api/get-collection-one?code=S1w5aTnQUwAtDUkrQbCbGN89hBkYLes3IJaFvQX9Qq9rAzFufyTSCg%3D%3D";
const summarizeTranscriptUrl = "https://inwise-node-functions1.azurewebsites.net/api/transcript-summarize?code=xc8L5f4a-XH_EhuyqCPESH8lfVIEiuGIqcoKK60NW8ViAzFuwPWH_w%3D%3D";

function MeetingOverviewTab(props: {
  activeTab: number;
  index: number;
  name: any;
}) {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorTertiary = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.500"
  );
  const { activeTab, index, name } = props;
  return (
    <Tab
      key={index}
      pb="0px"
      flexDirection="column"
      me="10px"
      bg="unset"
      _selected={{
        bg: "none",
      }}
      _focus={{ border: "none" }}
      minW="max-content"
    >
      <Flex align="center">
        <Text
          color={activeTab == index ? textColor : textColorTertiary}
          fontSize="lg"
          fontWeight="500"
        >
          {name}
        </Text>
      </Flex>
      <Box
        height="4px"
        w="100%"
        transition="0.1s linear"
        bg={activeTab == index ? "brand.500" : "transparent"}
        mt="15px"
        borderRadius="30px"
      />
    </Tab>
  );
}

function MeetingSummaryTab(props: { meetingSummaryContent: string[], isSummaryLoading: boolean }) {
  const textColor = useColorModeValue("navy.700", "white");
  const { meetingSummaryContent, isSummaryLoading } = props;
  if (!meetingSummaryContent) return null
  return (
    <Box>
      <Box paddingLeft="20px">
        {!isSummaryLoading ? meetingSummaryContent?.map((meetingSummaryPoint, index) => (
          <Box key={index}>
            <Text fontSize={"md"} color={textColor} ms="auto" me="6px">
              {meetingSummaryPoint}
            </Text>
          </Box>
        )) :
          <Box mt='6px'>
            <Flex dir="row" align='center' justify={'flex-start'}>
              <Spinner color='brand.500' thickness='3px' emptyColor='gray.200' />
              <Text fontSize={"md"} color={textColor} me="6px" ml='12px'>
                summarizing meeting transcript
              </Text>
            </Flex>
            <SkeletonText mt='4' noOfLines={7} spacing='4' skeletonHeight='2' />
          </Box>}
      </Box>
    </Box>
  );
}

function TranscriptTab(props: {
  meeting: Meeting,
  setGeneratedDetailsFunction: Function,
  setActiveTabFunction: Function,
  setIsLoadingFunction: Function,
}) {
  const { meeting, setGeneratedDetailsFunction, setActiveTabFunction, setIsLoadingFunction } = props;
  const [meetingTranscript, setMeetingTranscript] = useState<String>();
  const buttonRef = useRef(null);
  const textColor = useColorModeValue("navy.700", "white");
  useEffect(() => {
    if (meeting.meetingTranscriptId) {
      fetch(getCollectionItemUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // TODO: add JWT
        body: JSON.stringify({
          _id: meeting.meetingTranscriptId,
          collectionName: "MeetingTranscript"
        })
      })
        .then(res => res.json())
        .then(data => {
          let { item } = data as GetCollectionItemResponse;
          let { _id, wiseCompanyId, transcript, date, speakers } = item;
          setMeetingTranscript(transcript);
        })
        .catch((error: Error) => {
          console.log(error);
        });
    }
  }, []);


  function callSummarizeTranscriptAPI(meetingId: string, setGeneratedDetailsFunction: Function, setActiveTabFunction: Function) {
    console.log({ summarizeMeetingId: meetingId });
    fetch(summarizeTranscriptUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      // TODO: add JWT
      body: JSON.stringify({ meetingId })
    })
      .then(res => res.json())
      .then(data => {
        buttonRef.current.disabled = false;
        setIsLoadingFunction(false);
        setGeneratedDetailsFunction(data.result.updatedMeeting)
        console.log({ data });
      })
      .catch((error: Error) => {
        console.log(error);
      });
    buttonRef.current.disabled = true;
    buttonRef.current.innerText = "reprocess transcript"; // TODO: find a better way
    setActiveTabFunction(0);
    setIsLoadingFunction(true);
  }
  return (
    <Box px="20px">
      {meetingTranscript ?
        <Flex justify='space-between' mb='28px' mt='7px'>
          <Button
            ref={buttonRef}
            variant='darkBrand'
            fontSize='sm'
            borderRadius='16px'
            w={{ base: '160px', md: '160px' }}
            h='46px'
            onClick={() => callSummarizeTranscriptAPI(meeting._id, setGeneratedDetailsFunction, setActiveTabFunction)}>
            {meeting.generatedSummary ? "re" : ""}process transcript
          </Button>
        </Flex>
        : null
      }
      <Text color={textColor} fontSize="16px" fontWeight="400" style={{ whiteSpace: "pre-wrap" }}>
        {meetingTranscript ? meetingTranscript : "(no transcript)"}
      </Text>
    </Box>
  );
}

//TODO(KRIS) : this loses deal name meeting link etc everything thats in Meeting minus generatedDetails ( aka whats returned from 'transcript-summarize')
export default function MeetingSummaryCard(props: { meetingDetails: Meeting, setMeetingDetailsFunction: Function }) {
  const { meetingDetails, setMeetingDetailsFunction } = props
  let [generatedDetails, setGeneratedDetails] = useState<Meeting>(meetingDetails);
  let [activeTab, setActiveTab] = useState<number>(generatedDetails?.generatedSummary ? 0 : 1);
  let [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setMeetingDetailsFunction(generatedDetails)
  }, [generatedDetails]);

  return (
    <Card>
      <Tabs
        variant="soft-rounded"
        colorScheme="brandTabs"
        mb="60px"
        index={activeTab}
        onChange={(index) => setActiveTab(index)}
      >
        <TabList overflowX={{ sm: "scroll", lg: "unset" }}>
          <Flex>
            <MeetingOverviewTab
              activeTab={activeTab}
              index={0}
              name="Meeting Summary"
            />
            <MeetingOverviewTab
              activeTab={activeTab}
              index={1}
              name="Transcript"
            />
          </Flex>
        </TabList>
        <TabPanels>
          <TabPanel px="0px">
            <MeetingSummaryTab meetingSummaryContent={generatedDetails.generatedSummary} isSummaryLoading={isLoading} />
          </TabPanel>
          <TabPanel px="0px">
            <TranscriptTab
              meeting={meetingDetails}
              setGeneratedDetailsFunction={setGeneratedDetails}
              setActiveTabFunction={setActiveTab}
              setIsLoadingFunction={setIsLoading}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
}
